















import { Component, Prop, Vue } from 'vue-property-decorator'
import InputNumber from 'primevue/inputnumber'

Vue.component('InputNumber', InputNumber)

@Component({ inheritAttrs: false })
export default class AppInputCurrency extends Vue {
  @Prop({ default: () => { return 'currency' } }) mode!: string
  @Prop({ default: () => { return 'USD' } }) currency!: string
  @Prop({ default: () => { return 'en-US' } }) locale!: string
  @Prop({ default: () => { return '$' } }) placeholder!: string
}
