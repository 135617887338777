import ApplicationRecord from '@/models/application_record'
import { Valuation } from '@/models'
import capitalize from '@/filters/capitalize'

import {
  Model,
  Attr,
  HasOne
} from 'spraypaint'

@Model()
export class Investment extends ApplicationRecord {
  static jsonapiType = 'investments'

  @Attr name!: string
  @Attr investmentType!: string
  @Attr active!: boolean

  @Attr primaryOwnershipPercent!: number
  @Attr secondaryOwnershipPercent!: number
  @Attr secondaryOwnershipDetails!: string

  @Attr investmentDate!: Date
  @Attr investmentDateDetails!: string
  @Attr initialInvestmentAmount!: number
  @Attr initialInvestmentDetails!: string
  @Attr investmentPledgeAmount!: number
  @Attr investmentPledgeDetails!: string

  @Attr bankDebtAmount!: number
  @Attr bankDebtDetails!: string

  @Attr exitDate!: Date
  @Attr saleAmount!: number
  @Attr saleDetails!: string

  @Attr totalContributions!: number
  @Attr totalDistributions!: number
  @Attr latestInterestRate!: number
  @Attr latestBalance!: number

  @Attr flaggedValuationId!: string | null

  @HasOne() flaggedValuation!: Valuation

  get investmentTypeDisplay (): string {
    if (!this.investmentType) return ''
    return capitalize(this.investmentType.split('_').join(' '))!
  }
}

export default Investment
